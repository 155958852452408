import { Checkbox, Collapse, DatePicker, Popover, Radio, Select, TimePicker } from 'antd';
import { ZeroQuill } from 'components/Shared/ZeroQuill.js';
import dayjs from "dayjs";
import NotificationAlert from 'other/NotificationAlert.js';
import { Component, useCallback, useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SignatureCanvas from 'react-signature-canvas';
import {
    dateFormatterNoTime,
    generateUUID,
    htmlToText,
    safeProfilePic,
    shouldFormFieldDisplay,
    textToParagraphs
} from '../../other/Helper.js';
import safe_get from '../../other/SafeGet.js';
import ConfirmModal from '../Shared/ConfirmModal.js';
import DynamicList from './Fields/DynamicList.js';
import UserDropdown from './Fields/UserDropdown.js';
import FileUploadsField from './FileUploadsField.js';
import FormFieldCommentAttachment from './FormFieldCommentAttachment.js';
import FormFieldEditorModal from './FormFieldEditorModal.js';
import FormFieldMenu from './FormFieldMenu.js';
import styles from './FormFieldsRenderer.module.css';
import SubmissionAttachmentField from "./SubmissionAttachmentField";

import '../../assets/css/form-builder.css';

var saveContentTimeout = null;

function getMaxPoints(field) {
    const scores = [];
    let points = 0;

    for (const option of field.options) {
        if (Number.isInteger(option.score)) {
            scores.push(option.score);
        }
    }

    if (field.element === 'RadioButtons') {
        points = Math.max(...scores);
        if (field.audit && points === 0) {
            points = 1;
        }
    } else if (field.element === 'Dropdown') {
        points = Math.max(...scores);
    } else if (field.element === 'Checkboxes') {
        points = scores.reduce((acc, val) => acc + val);
    }

    return points || 0;
}

function TextAreaField({field, answerData, readOnly, onChange}) {
    const changeHandler = useCallback((value) => {
        onChange(field.id, value);
    }, [field.id, onChange]);

    const value = useMemo(
        () => (answerData?.htmlValue ?? textToParagraphs(answerData?.value) ?? ""), 
        [answerData?.htmlValue, answerData?.value]
    );

    return (
        <>
            <ZeroQuill
                disableUploads
                defaultValue={value}
                onChange={changeHandler}
                height={120}
                readOnly={readOnly}
            />

            <div className='mar-top-10'>
                <div>Plain-Text Debug:</div>
                <pre>{answerData?.value}</pre>
            </div>
        </>
    );
}

class FormFieldsRenderer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            readyToSaveDraft: true,
            form_fields: [],
            field_answers: [],
            answers_data: props.answers_data || [],
            saving: false
        };

        this.initializeAnswersStructure = this.initializeAnswersStructure.bind(this);
        this.optionSelect = this.optionSelect.bind(this);
        this.checkboxSelect = this.checkboxSelect.bind(this);
        this.dropdownSelect = this.dropdownSelect.bind(this);
        this.handleTagsChange = this.handleTagsChange.bind(this);
        this.handleDateSelect = this.handleDateSelect.bind(this);
        this.inputChange = this.inputChange.bind(this);

        this.updateField = this.updateField.bind(this);

        this.updateAnswers = this.updateAnswers.bind(this);
    }

    componentDidUpdate(prevProps) {
        if ((this.props.form_fields !== prevProps.form_fields && this.props.is_builder)
            || this.props.refreshKey !== prevProps.refreshKey) {

            this.setState({
                form_fields: this.props.form_fields,
                field_answers: this.props.field_answers
            }, this.initializeAnswersStructure);
        }

        if (this.props.answers_data !== prevProps.answers_data) {
            this.setState({answers_data: this.props.answers_data});
        }

        if (this.props.isAnalytics && !this.props.is_section && this.props.field_answers !== prevProps.field_answers) {
            this.setState({
                field_answers: this.props.field_answers,
                answers_data: []
            }, this.initializeAnswersStructure);
        }
    }

    componentDidMount() {
        if (!this.props.is_section) {
            this.setState({
                form_fields: this.props.form_fields,
                field_answers: this.props.field_answers
            }, this.initializeAnswersStructure);
        } else {
            this.setState({
                form_fields: this.props.form_fields,
                field_answers: this.props.field_answers,
                loading: false
            })
        }
    }

    initializeAnswersStructure() {
        var answers = []

        var fields = safe_get(this.state, "form_fields", []);
        var field_answers = safe_get(this.state, "field_answers", []);
        const answers_data = safe_get(this.state, 'answers_data', []);

        fields.forEach(function (field) {
            let found = field_answers.find(function (obj) {
                return obj.id === field.id
            });

            let foundAnswersData = answers_data.find(obj => obj.id === field.id);

            var answer = {}

            if (field.element === "RadioButtons") {
                answer = {
                    "label": "Multiple Choice",
                    "value": found ? found.value : [],
                    "audit": field.audit,
                }
            } else if (field.element === "Checkboxes") {
                answer = {
                    "label": field.label,
                    "value": found ? found.value : [],
                }
            } else if (field.element === "Dropdown") {
                answer = {
                    "label": field.label,
                    "value": found ? found.value : [],
                }
            } else if (field.element === "Tags") {
                answer = {
                    "label": field.label,
                    "value": found ? found.value : [],
                }
            } else if (field.element === "DatePicker") {
                let value = found ? found.value : ""
                answer = {
                    "label": field.label,
                    "value": value ? dayjs(value, 'YYYY-MM-DD') : undefined,
                }
            } else if (field.element === 'TimePicker') {
                let value = found ? found.value : "";
                answer = {
                    'label': field.label,
                    'value': value ? dayjs(value, 'HH:mm') : undefined,
                }
            } else if (field.element === 'TextArea') {
                answer = {
                    "label": field.label,
                    "value": found ? found.value : "",
                }

                if (found && found.htmlValue) {
                    answer.htmlValue = found.htmlValue;
                }
            } else if (["TextInput", "NumberInput", "DynamicListInput"].includes(field.element)) {
                answer = {
                    "label": field.label,
                    "value": found ? found.value : "",
                }
            } else if (field.element === "Signature") {
                answer = {
                    "label": field.label,
                    "value": found ? found.value : "",
                }
            } else if (field.element === 'UserDropdown') {
                answer = {
                    "label": field.label,
                    "value": found ? found.value : [],
                }
            }

            if (foundAnswersData) {
                answer['value'] = foundAnswersData.value;
            }

            answer["id"] = field.id;
            answer["element"] = field.element;
            answer["name"] = field.field_name;
            answer["sectionId"] = field.sectionId
            answer["required"] = field.required;
            answer["required_error"] = field.required_error

            let comment = found ? found.comment : "";

            answer["comment"] = comment;
            answer["comment_saved"] = comment !== "" && comment !== undefined && comment !== null;
            answer["attachments"] = found ? found.attachments : [];
            answer["related_post_uuid"] = found ? found.related_post_uuid : undefined;
            answer["post_title"] = found ? found.post_title : undefined;
            answer["post_status"] = found ? found.post_status : undefined;

            answers.push(answer);
        });

        this.setState({
            answers_data: answers,
            loading: false
        }, () => {
            this.calculateScore(answers);
        });
    }

    optionSelect(e) {
        if (this.props.is_builder || this.props.isAnalytics || this.props.isSubmissionView) {
            document.activeElement.blur();
            return;
        }

        var option = e.target

        var answers = [...this.state.answers_data]
        var found = answers.find(function (obj) {
            return obj.id === option.id
        })

        if (found) {
            var index = answers.indexOf(found);
            found["value"] = [option.value]
            answers[index] = found;

            this.setState({
                answers_data: answers
            }, () => {
                this.updateAnswers()
            });
        }
    }

    checkboxSelect(e) {
        if (this.props.is_builder || this.props.isAnalytics || this.props.isSubmissionView) {
            document.activeElement.blur();
            return;
        }

        var option = e.target

        var answers = [...this.state.answers_data]
        var found = answers.find(function (obj) {
            return obj.id === option.id
        })

        if (found) {
            var index = answers.indexOf(found);
            var value = found.value

            var answer_index = value.indexOf(option.value)
            if (answer_index >= 0) {
                value.splice(answer_index, 1)
            } else {
                value.push(option.value)
            }

            found["value"] = value
            answers[index] = found;

            this.setState({
                answers_data: answers
            }, () => {
                this.updateAnswers()
            });
        }
    }

    dropdownSelect(field_id, value) {
        var answers = [...this.state.answers_data]
        var found = answers.find(function (obj) {
            return obj.id === field_id
        });

        if (found) {
            var index = answers.indexOf(found);
            found["value"] = [value];
            answers[index] = found;

            this.setState({
                answers_data: answers
            }, () => {
                this.updateAnswers()
            });
        }
    }

    handleTagsChange(field_id, value) {
        var answers = [...this.state.answers_data]
        var found = answers.find(function (obj) {
            return obj.id === field_id
        });

        if (found) {
            var index = answers.indexOf(found);
            found["value"] = value
            answers[index] = found;

            this.setState({
                answers_data: answers
            }, () => {
                this.updateAnswers()
            });
        }
    }

    handleDynamicListChange(field_id, value) {
        const answers = [...this.state.answers_data];
        const found = answers.find(answer => answer.id === field_id);

        if (found) {
            const index = answers.indexOf(found);
            found["value"] = value
            answers[index] = found;

            this.setState({
                answers_data: answers
            }, () => {
                this.updateAnswers()
            });
        }
    }

    handleUserDropdownChange(field_id, values) {
        const answers = [...this.state.answers_data];
        const found = answers.find(answer => answer.id === field_id);

        if (found) {
            const index = answers.indexOf(found);
            found["value"] = values;
            answers[index] = found;

            this.setState({
                answers_data: answers
            }, () => {
                this.updateAnswers()
            });
        }
    }

    quillInputChange = (fieldId, htmlValue) => {
        const answers = [...this.state.answers_data];
        const fieldIndex = answers.findIndex(a => a.id === fieldId);
        if (fieldIndex !== -1) {
            const plainTextValue = htmlToText(htmlValue);
            answers[fieldIndex] = {
                ...answers[fieldIndex],
                value: plainTextValue,
                htmlValue,
            };

            this.setState({answers_data: answers}, () => {
                if (this.props.setSubmissionLock) {
                    this.props.setSubmissionLock(true);
                }
                clearTimeout(saveContentTimeout);
                saveContentTimeout = setTimeout(() => {
                    if (this.props.setSubmissionLock) {
                        this.props.setSubmissionLock(false);
                    }
                    this.updateAnswers();
                }, 1000);
            })
        }
    }

    inputChange(event) {
        const target = event.target;
        const id = target.id;
        const text = target.value;

        var answers = [...this.state.answers_data]
        var found = answers.find(function (obj) {
            return obj.id === id
        })

        if (found) {
            var index = answers.indexOf(found);
            found["value"] = text
            answers[index] = found;

            this.setState({
                answers_data: answers,
            }, () => {
                // this.updateAnswers();
                if (this.props.setSubmissionLock) {
                    this.props.setSubmissionLock(true);
                }
                clearTimeout(saveContentTimeout);
                saveContentTimeout = setTimeout(() => {
                    if (this.props.setSubmissionLock) {
                        this.props.setSubmissionLock(false);
                    }
                    this.updateAnswers();
                }, 1000);
            });
        }
    }

    handleDateSelect(field_id, value) {
        var answers = [...this.state.answers_data]
        var found = answers.find(function (obj) {
            return obj.id === field_id
        })

        if (found) {
            var index = answers.indexOf(found);
            found["value"] = value
            answers[index] = found;

            this.setState({
                answers_data: answers
            }, () => {
                this.updateAnswers()
            });
        }
    }

    handleTimeSelect(field_id, value) {
        var answers = [...this.state.answers_data]
        var found = answers.find(function (obj) {
            return obj.id === field_id
        })

        if (found) {
            var index = answers.indexOf(found);
            found["value"] = value
            answers[index] = found;

            this.setState({
                answers_data: answers
            }, () => {
                this.updateAnswers()
            });
        }
    }

    saveSignature = (field_id, signature_data) => {
        var answers = [...this.state.answers_data]
        var found = answers.find(function (obj) {
            return obj.id === field_id
        });

        if (found) {
            let index = answers.indexOf(found);
            found["value"] = signature_data
            answers[index] = found;

            this.setState({
                answers_data: answers
            }, () => {
                this.updateAnswers();
            });
        }
    }

    updateField(answer_data, index) {
        var answers = [...this.state.answers_data];
        var found = answers.find(function (obj) {
            return obj.id === answer_data.id
        })

        if (found) {
            const foundIndex = answers.indexOf(found);
            answers[foundIndex] = answer_data;

            this.setState({answers_data: answers}, () => {
                this.updateAnswers()
            });
        }
    }

    calculateScore(answers) {
        if (!this.props.updateScore) {
            return;
        }

        let total_points = 0;
        let scored_points = 0;

        let form_fields = [...this.state.form_fields];

        answers.forEach(answer => {
            let field = form_fields.find(function (obj) {
                return obj.id === answer.id
            });

            let answer_val = answer.value;

            let shouldShowSection = true;
            if (field.sectionId) {
                let section = form_fields.find(obj => {
                    return obj.id === field.sectionId
                });
                shouldShowSection = shouldFormFieldDisplay(section, this.state.answers_data)
            }

            if ((field.is_scored || field.audit) && answer_val.length > 0 && answer_val[0] && shouldFormFieldDisplay(field, this.state.answers_data) && shouldShowSection) {

                let score = 0;
                if (field.element === "Checkboxes") {
                    answer_val.forEach(element => {

                        let answer_score = safe_get(field.options.find(function (obj) {
                            return obj.key === element;
                        }), "score", 0);

                        score += answer_score;
                    });
                } else {
                    if (field.audit) {
                        const option = field.options.find(obj => obj.key === answer_val[0])
                        if (option) {
                            score = safe_get(option, "score", null);

                            if (score === null) {
                                const text = safe_get(option, "text", "");
                                if (text === 'YES') {
                                    score = 1;
                                } else if (text === 'NO') {
                                    score = 0;
                                }
                            }
                        }
                    } else {
                        score = safe_get(field.options.find(obj => obj.key === answer_val[0]), "score", 0);
                    }
                }

                if (!(field.audit && answer_val[0] === "radiobuttons_option_na") && this.shouldShowFieldScore(field, answer_val)) {
                    total_points += field.points ?? getMaxPoints(field);
                }
                scored_points += score;
            }

        });

        if (total_points > 0) {
            let final_score = Math.round((scored_points / total_points) * 100);
            if (!isNaN(final_score)) {
                this.props.updateScore(scored_points, total_points, final_score)
            }
        } else {
            this.props.updateScore(0, 0, 0);
        }

    }

    shouldShowFieldScore = (field, answer) => {
        let shouldShow = false;

        answer.forEach(answer_val => {
            let option = field.options.find(obj => {
                return obj.key === answer_val
            });

            if (option) {
                shouldShow = true;
            }
        });

        return shouldShow;
    }

    calculateFieldScore = (field, answer) => {
        if (
            answer.length === 0 ||
            !answer[0] ||
            (field.audit && (answer.length === 0 || answer[0] === "radiobuttons_option_na")) ||
            !this.shouldShowFieldScore(field, answer)
        ) {
            return "0 / 0 points (skipped)"
        }

        let points = 0;
        answer.forEach(answer_val => {
            let option = field.options.find(obj => {
                return obj.key === answer_val
            });
            if (option && option.score !== undefined) {
                points += option.score
            } else if (option && field.audit) {
                if (option.value === 'yes') {
                    points += 1
                }
            }
        });

        return `${points} / ${field.points} points`
    }

    isFailedField = (field, answer) => {
        if (!this.props.isSubmissionView || !answer || answer.length === 0 || !answer[0]) {
            return false
        }

        let found = safe_get(field, "options", []).find(item => {
            return item.key === answer[0]
        });

        return found ? found.is_failure : false
    }

    updateAnswersFromSection = (section_answers) => {
        let form_answers = [...this.state.answers_data]
        let form_answers_ids = form_answers.map(field => field.id);

        section_answers.forEach(answer => {
            let index = form_answers_ids.indexOf(answer.id)
            if (index >= 0) {
                form_answers[index] = answer
            } else {
                form_answers.push(answer);
            }
        });

        this.setState({
            answers_data: form_answers
        }, () => {
            this.updateAnswers()
        });
    }

    updateAnswers() {
        if (!this.props.is_builder && !this.state.saving) {
            this.setState({saving: true}, () => {
                var answer_fields = [];
                var answers = [...this.state.answers_data]

                answers.forEach((answer_field) => {
                    var value = answer_field.value;

                    if ((value && value.length > 0 && value[0]) || (dayjs.isDayjs(value)) || answer_field.element === "Signature" || answer_field.element === "Attachments") {

                        var answer = {
                            "id": answer_field.id,
                            "label": answer_field.text || answer_field.label,
                            "element": answer_field.element,
                            "name": answer_field.name,
                            "sectionId": answer_field.sectionId,
                            "required": answer_field.required,
                            "comment": answer_field.comment,
                            "comment_saved": answer_field.comment_saved,
                            "attachments": answer_field.attachments,
                            "related_post_uuid": answer_field.related_post_uuid,
                            "post_status": answer_field.post_status,
                            "points": answer_field.points,
                            "is_scored": answer_field.is_scored,
                        }

                        if (answer_field.element === "DatePicker") {
                            answer["value"] = dayjs(value, 'YYYY/MM/DD').format("YYYY-MM-DD")
                        } else if (answer_field.element === 'TimePicker') {
                            answer['value'] = dayjs(value, 'HH:mm').format('HH:mm')
                        } else if (answer_field.element === 'TextArea') {
                            answer['value'] = value;
                            const { htmlValue } = answer_field;
                            if (htmlValue) {
                                answer['htmlValue'] = htmlValue;
                            }
                        } else {
                            answer["value"] = value
                        }

                        if (answer_field.audit) {
                            answer["audit"] = true
                        }
                        answer_fields.push(answer);
                    }
                });

                if (this.props.updateAnswers) {
                    this.props.updateAnswers(answer_fields);
                }

                setTimeout(() => {
                    this.calculateScore([...answer_fields]);
                }, 1000);
                this.setState({saving: false});
            });
        }
    }

    copyFieldInternal = (field_to_copy) => {
        const field = {...field_to_copy};

        // deep copy options
        if (field.options) {
            field.options = [...field.options]
            for (let i = 0; i < field.options.length; i++) {
                field.options[i] = {...field.options[i]}
            }
        }

        field["id"] = generateUUID();

        if (field.label) {
            field.label += ' (Copy)';
        } else if (field.element === 'FileUploads' && field.content) {
            field.content += ' (Copy)';
        }

        delete field.custom_logic;
        return field;
    }

    copyField = (field_to_copy, index) => {
        const form_fields = [...this.state.form_fields];
        const field = this.copyFieldInternal(field_to_copy);

        if (field.sectionId) {
            const showFailureNotification = () => NotificationAlert("error", "", "Could not copy field", 5);

            // We're copying a field inside a section, need to tweak the section's children array
            const section = form_fields.find(section => section.id === field.sectionId);
            if (!section) {
                console.error("Cannot find section with ID", field.sectionId, "for copyField");
                showFailureNotification();
                return;
            }

            // Find out where we need to add the new field's ID (1 after copied field) inside the section's children array
            const originalFieldIndex = section.children.findIndex(id => id === field_to_copy.id);
            if (originalFieldIndex < 0) {
                console.error("Cannot find field in section's children array with ID of", field_to_copy.id);
                showFailureNotification();
                return;
            }

            section.children.splice(originalFieldIndex + 1, 0, field.id);
        }

        form_fields.splice(index + 1, 0, field);

        if (this.props.saveForm) {
            this.props.saveForm(form_fields);
        } else if (this.props.saveFormFromSection) {
            this.props.saveFormFromSection(form_fields)
        }
    }

    copySection = (sectionToCopy, index) => {
        // copy the original section (gets a new id)
        const newSection = this.copyFieldInternal(sectionToCopy);
        // get each field in the original section
        const sectionFieldsToCopy = this.state.form_fields.filter(field => field.sectionId === sectionToCopy.id);
        // copy each field from the original section and change sectionId to the new section's id
        const newSectionFields = 
            sectionFieldsToCopy
            .map(field => ({
                ...this.copyFieldInternal(field),
                sectionId: newSection.id,
            }));
        // overwrite children on new section with correct IDs
        newSection.children = newSectionFields.map(field => field.id);
        // splice new section + fields into form_fields and save
        const formFields = [...this.state.form_fields];
        formFields.splice(index + 1, 0, ...[newSection, ...newSectionFields]);
        this.props.saveForm(formFields);
    }

    checkBeforeDelete = (field, index) => {
        let canDelete = true;
        let field_id = field.id;
        let all_fields = [...this.state.form_fields];
        all_fields.forEach(field => {
            if (field.custom_logic && field.custom_logic.trigger_field_id === field_id) {
                canDelete = false;
            }
        });

        if (canDelete) {
            this.setState({deleteFieldIndex: index, showDeleteModal: true});
        } else {
            this.setState({deleteFieldIndex: index, showDeleteErrorModal: true});
        }

    }

    deleteField = () => {
        this.props.deleteField(this.state.deleteFieldIndex);
        setTimeout(() => {
            this.setState({showDeleteModal: false, showDeleteErrorModal: false, deleteFieldIndex: undefined});
        }, 600);
    }

    saveField = (field, index) => {
        if (field && index >= 0) {
            var updated_form_fields = [...this.state.form_fields];
            updated_form_fields[index] = field;
            this.setState({form_fields: updated_form_fields}, () => {
                this.props.saveForm(updated_form_fields);
            });
        }
    }

    handleMenuClick = (key) => {
        let toolbar_items = [...this.props.toolbar_items]
        if (this.props.is_section) {
            toolbar_items = toolbar_items.filter((item) => {
                return item.element !== "Section" && item.element !== "PageBreak"
            })
        }

        let field = toolbar_items[key];

        if (field) {

            if (field.element === "Section") {
                field.children = [];
            }

            if (this.state.activeIndex > -1) {
                let insertIndex = this.state.activeIndex;
                this.addField(field, insertIndex + 1);
            } else {
                this.addFieldToSection(field)
            }
        }
    }

    addField = (new_field, index) => {
        var form_fields = [...this.state.form_fields];

        let field = {...new_field};

        // deep copy options
        if (field.options) {
            field.options = [...field.options]
            for (let i = 0; i < field.options.length; i++) {
                field.options[i] = {...field.options[i]}
            }
        }

        field["id"] = generateUUID();
        delete field["icon"];

        form_fields.splice(index, false, field);
        this.props.saveForm(form_fields);
        this.setState({activeIndex: -1});
    }

    addFieldToSection = (field) => {
        let form_fields = [...this.state.form_fields];

        let section_field = form_fields.find((obj) => {
            return obj.id === this.props.section_id
        });
        let section_field_children = safe_get(section_field, "children", []);

        let new_field = {...field};
        // deep copy options
        if (new_field.options) {
            new_field.options = [...new_field.options]
            for (let i = 0; i < new_field.options.length; i++) {
                new_field.options[i] = {...new_field.options[i]}
            }
        }

        new_field["id"] = generateUUID();
        new_field["sectionId"] = section_field.id
        delete new_field["icon"];

        section_field_children.push(new_field.id);
        section_field["children"] = section_field_children;

        form_fields.push(new_field);

        if (this.props.saveForm) {
            this.props.saveForm(form_fields);
        } else if (this.props.saveFormFromSection) {
            this.props.saveFormFromSection(form_fields)
        }

        this.setState({activeIndex: -1, activeIndexSection: -1, activeSection: null});
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = this.reorder(
            this.state.form_fields,
            result.source.index,
            result.destination.index
        );

        this.setState({form_fields: items});

        if (this.props.is_section) {
            this.props.saveFormFromSection(items);
        } else {
            this.props.saveForm(items);
        }
    }

    saveFormFromSection = (fields) => {
        let form_fields = fields;

        let ordered_section_fields_ids = fields;
        ordered_section_fields_ids.filter((field) => {
            return field.sectionId === this.props.section_id
        }).map(field => field.id)


        for (var i in form_fields) {
            let field = form_fields[i]
            if (field.id === this.props.section_id) {
                field["children"] = ordered_section_fields_ids
                break;
            }
        }

        this.props.saveForm(form_fields)

    }

    getSectionFields = (section_id) => {
        let form_fields = [...this.state.form_fields];
        let section_fields = form_fields.filter((field) => {
            return field.sectionId === section_id
        });

        return section_fields || []
    }

    getTextAnswers(answer_data, options) {
        const values = safe_get(answer_data, "value", [])
        const textAnswers = [];
        values.map(value => {
            options.map(option => {
                if (value === option.key) {
                    textAnswers.push(option.text);
                }
            });
        });

        return textAnswers;
    }

    handleAttachmentUpload = answer_data => {
        this.updateField(answer_data);
    }

    handleFileUploadsChange = (attachments, field, index) => {
        field.attachments = attachments;
        this.saveField(field, index);
    }

    renderDropdown(index, field, answer_data) {
        if (this.props.isAnalytics || this.props.isSubmissionView) {
            const answers = this.getTextAnswers(answer_data, field.options);
            return (
                <div className="answer-border">
                    {answers.map((answer, k) => <p key={k}>{answer}</p>)}
                </div>
            )
        }

        return (
            <Select
                key={field.id + "_" + index}
                id={field.id}
                style={{width: '100%'}}
                placeholder="Please select"
                value={safe_get(answer_data, "value", undefined)}
                onChange={(value) => {
                    this.dropdownSelect(field.id, value)
                }}
                disabled={this.props.is_builder}
                dropdownStyle={{zIndex: '1200'}}
                virtual={false}
            >
                {
                    safe_get(field, "options", []).length > 0 &&
                    field.options.map((option, sub_index) => (
                        <Select.Option key={option.key + sub_index} field_id={field.id}
                                       value={option.key}>{option.text}</Select.Option>
                    ))
                }
            </Select>
        )
    }

    renderDynamicDropdown(index, field, answer_data) {
        if (this.props.isAnalytics || this.props.isSubmissionView) {
            return (
                <div className="answer-border">
                    <p>{safe_get(answer_data, "value", "No response selected.")}</p>
                </div>
            )
        }

        const listId = safe_get(field, 'listId', null);

        return (
            <div key={field.id + '_' + index}>
                <DynamicList
                    disabled={this.props.is_builder}
                    listId={listId}
                    value={safe_get(answer_data, 'value', '')}
                    onChange={value => this.handleDynamicListChange(field.id, value)}
                />
            </div>
        )
    }

    renderUserDropdown(index, field, answer_data) {
        const users = safe_get(answer_data, 'value', []);

        if (this.props.isAnalytics || this.props.isSubmissionView) {

            return (
                <div style={{display: 'flex', flexWrap: 'wrap', gap: '1rem'}}>
                    {users.length === 0 &&
                        <p className='zero-dark-grey'>No response selected.</p>
                    }
                    {
                        users.map(user => (
                            <div key={user.uuid} className={styles.userDropdownItemWrapper}>
                                {safeProfilePic(user, "img-circle bulletin", "bulletin", {
                                    display: "inline-block",
                                    marginRight: '0.5rem'
                                })}
                                {user.first_name} {user.last_name}
                            </div>
                        ))
                    }
                </div>
            );
        }

        return (
            <div key={field.id + '_' + index}>
                <UserDropdown
                    selectedUsers={users}
                    onChange={values => this.handleUserDropdownChange(field.id, values)}
                    disabled={this.props.is_builder}
                />
            </div>
        )
    }

    renderTags(index, field, answer_data) {
        if (this.props.isAnalytics || this.props.isSubmissionView) {
            const answers = this.getTextAnswers(answer_data, field.options);
            if (!answers.length) {
                return (
                    <div className="answer-border">

                    </div>
                )
            }

            return (
                <div className="tag-flex">
                    {answers.map((answer, k) => <div key={k} className="answer-border">{answer}</div>)}
                </div>
            )
        }

        return (
            <Select
                key={field.id + "_" + index}
                id={field.id}
                mode="multiple"
                style={{width: '100%', marginBottom: "5px"}}
                placeholder="Please select"
                defaultValue={safe_get(answer_data, "value", [])}
                onChange={(value) => {
                    this.handleTagsChange(field.id, value)
                }}
                disabled={this.props.is_builder}
            >
                {
                    safe_get(field, "options", []).length > 0 &&
                    field.options.map((option, sub_index) => (
                        <Select.Option key={option.key + sub_index} field_id={field.id}
                                       value={option.key}>{option.text}</Select.Option>
                    ))
                }
            </Select>
        )
    }

    renderTextInput(field, answer_data) {
        if (this.props.isAnalytics || this.props.isSubmissionView) {
            return (
                <div className="answer-border">
                    <p>{safe_get(answer_data, "value", "")}</p>
                </div>
            )
        }

        return (
            <input
                type="text"
                className="form-control custom-placeholder mar-btm-5"
                id={field.id}
                defaultValue={safe_get(answer_data, "value", "")}
                placeholder="Text input"
                onChange={this.inputChange}
                disabled={this.props.is_builder}
            />
        )
    }

    renderNumberInput(field, answer_data) {
        if (this.props.isAnalytics || this.props.isSubmissionView) {
            return (
                <div className="answer-border">
                    <p>{safe_get(answer_data, "value", "")}</p>
                </div>
            )
        }

        return (
            <input
                type="number"
                className="form-control custom-placeholder mar-btm-5"
                id={field.id}
                placeholder="Number input"
                defaultValue={safe_get(answer_data, "value", "")}
                onChange={this.inputChange}
                disabled={this.props.is_builder}
            />
        )
    }

    renderAttachmentInput(field, answer_data) {
        return (
            <SubmissionAttachmentField
                form_uuid={this.props.form_uuid}
                submission_uuid={this.props.submission_uuid}
                field_uuid={field.id}
                answer_data={answer_data}
                is_builder={this.props.is_builder}
                is_preview={this.props.is_preview}
                is_submission={this.props.is_submission}
                isSubmissionView={this.props.isSubmissionView}
                isComment={this.props.isComment}
                isAnalytics={this.props.isAnalytics}
                onFileUpload={this.handleAttachmentUpload}
            />
        )
    }

    renderTextArea(field, answer_data) {
        if (this.props.isAnalytics || this.props.isSubmissionView) {
            let content = <p style={{whiteSpace: "pre-wrap"}}>{safe_get(answer_data, "value", "")}</p>
            if (answer_data?.htmlValue) {
                content = <div className="html-text-area-output" dangerouslySetInnerHTML={{__html: answer_data.htmlValue}} />
            }
            return (
                <div className="answer-border">
                    {content}
                </div>
            )
        }

        return (
            <TextAreaField
                field={field}
                answerData={answer_data}
                onChange={this.quillInputChange}
                readOnly={this.props.is_builder}
            />
        );
    }

    renderDateInput(field, answer_data) {
        if (this.props.isAnalytics || this.props.isSubmissionView) {
            let date = safe_get(answer_data, "value", "");
            if (date) {
                if (typeof date === 'string') {
                    date = dayjs(date, 'YYYY-MM-DD') ? dayjs(date, 'YYYY-MM-DD') : undefined
                }
                date = dateFormatterNoTime(date / 1000);
                return <span className="answer-border" style={{display: 'inline-block', minWidth: 150}}>{date}</span>
            } else {
                return <div className="answer-border" style={{width: 150}}>&nbsp;</div>
            }
        } else if (this.props.is_builder) {
            return (
                <input
                    className="form-control custom-placeholder mar-btm-5"
                    id={field.id}
                    placeholder="Select a date"
                    disabled
                    style={{width: "150px"}}
                />
            )
        }

        // TODO: Value should already be a moment object, but in certain cases it's a string. Should find root cause.
        let value = safe_get(answer_data, "value", undefined);
        if (typeof value === 'string') {
            value = value ? dayjs(value, 'YYYY-MM-DD') : undefined;
        }

        return (
            <DatePicker
                className="mar-btm-5"
                value={value}
                format={"MM/DD/YYYY"}
                onChange={(value) => {
                    this.handleDateSelect(field.id, value)
                }}
            />
        )
    }

    renderTimeInput(field, answer_data) {
        if (this.props.isAnalytics || this.props.isSubmissionView) {
            let time = safe_get(answer_data, "value", "");
            if (time) {
                if (typeof time === 'string') {
                    time = time ? dayjs(time, 'HH:mm') : undefined
                }

                time = time.format('h:mm A');
                return <span className="answer-border" style={{display: 'inline-block', minWidth: 150}}>{time}</span>
            } else {
                return <div className="answer-border" style={{width: 150}}>&nbsp;</div>
            }
        } else if (this.props.is_builder) {
            return (
                <input
                    className="form-control custom-placeholder mar-btm-5"
                    id={field.id}
                    placeholder="Select a time"
                    disabled
                    style={{width: "150px"}}
                />
            )
        }

        // TODO: Value should already be a moment object, but in certain cases it's a string. Should find root cause.
        // Update: This comes from value being inside of a section
        let value = safe_get(answer_data, "value", undefined);
        if (typeof value === 'string') {
            value = value ? dayjs(value, 'HH:mm') : undefined
        }

        return (
            <TimePicker
                className="mar-btm-5"
                value={value}
                format={"h:mm A"}
                onChange={(value) => {
                    this.handleTimeSelect(field.id, value)
                }}
            />
        )
    }

    renderFileUploads(index, field) {
        return (
            <FileUploadsField
                form_uuid={this.props.form_uuid}
                field={field}
                is_builder={this.props.is_builder}
                is_preview={this.props.is_preview}
                is_submission={this.props.is_submission}
                isSubmissionView={this.props.isSubmissionView}
                isComment={this.props.isComment}
                isAnalytics={this.props.isAnalytics}
                onFileUpload={(attachments) => {
                    this.handleFileUploadsChange(attachments, field, index)
                }}
            />
        );
    }

    render() {
        let {
            form_uuid,
            is_preview,
            is_builder,
            is_section,
            is_submission,
            isSubmissionView,
            isComment,
            toolbar_items,
            icons,
            isAnalytics,
            enableCustomLogicForAnalytics,
        } = this.props
        let answers = [...this.state.answers_data];

        var menu = (
            <FormFieldMenu items={this.props?.toolbar_items || []} onClick={this.handleMenuClick}/>
        );

        let section_toolbar_items = [...safe_get(this.props, "toolbar_items", [])].filter((item) => {
            return (item.element !== "Section") && (item.element !== "PageBreak")
        });
        var section_menu = (
            <FormFieldMenu items={this.props?.toolbar_items || []} onClick={this.handleMenuClick}/>
        );

        if (this.state.loading) {
            return null
        } else {

            let rootClassName = "react-form-builder-form";
            if (is_builder) {
                rootClassName += " builder";
            }
            if (this.props.className) {
                rootClassName += " " + this.props.className;
            }

            return (
                <div className={rootClassName}>

                    {
                        this.state.showEditFieldModal &&
                        <FormFieldEditorModal
                            show={this.state.showEditFieldModal}
                            cancel={() => {
                                this.setState({showEditFieldModal: false, editFieldIndex: undefined});
                            }}
                            field_index={this.state.editFieldIndex}
                            fields={this.state.form_fields}
                            saveField={this.props.saveField}
                            form_uuid={this.props.form_uuid}
                        />
                    }
                    {
                        this.state.showDeleteModal &&
                        <ConfirmModal
                            show={this.state.showDeleteModal}
                            cancel={() => {
                                this.setState({showDeleteModal: false, deleteFieldIndex: undefined});
                            }}
                            title={"Confirmation"}
                            body={"Are you sure you want to delete this field?"}
                            confirmButtonName={"Delete field"}
                            confirm={this.deleteField}
                        />
                    }
                    {
                        this.state.showDeleteErrorModal &&
                        <ConfirmModal
                            show={this.state.showDeleteErrorModal}
                            cancel={() => {
                                this.setState({showDeleteErrorModal: false, deleteFieldIndex: undefined});
                            }}
                            title={"Confirmation"}
                            body={"This field is a trigger for another field with custom logic. Deleting this field will remove custom logic."}
                            confirmButtonName={"Delete"}
                            confirm={this.deleteField}
                        />
                    }

                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Droppable droppableId="form-droppable" isDropDisabled={!is_builder}>
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} >
                                    {
                                        safe_get(this.props, "pages", []).map((page_fields, pageIndex) => (

                                            this.props.page === pageIndex &&
                                            page_fields.map((field, index) => {

                                                let element = field.element;
                                                let answer_data = answers.find(function (obj) {
                                                    return obj.id === field.id
                                                });

                                                const shouldDisplayField = shouldFormFieldDisplay(field, answers, isComment, (is_builder || (!enableCustomLogicForAnalytics && isAnalytics)), this.props.section, (is_submission || is_preview || isSubmissionView || (enableCustomLogicForAnalytics && isAnalytics)));

                                                if (shouldDisplayField) {
                                                    return (
                                                        <Draggable key={"draggable" + element + index}
                                                                   draggableId={element + index} index={index}
                                                                   isDragDisabled={!is_builder}>
                                                            {(provided) => (
                                                                <div
                                                                    className={"SortableItem rfb-item " + (is_section ? "nested " : "") + (element === "Section" ? "section-container" : "") + ((this.state.activeIndex === index || this.state.activeIndexSection === index) ? "active" : "")} {...provided.draggableProps} {...provided.dragHandleProps}
                                                                    ref={provided.innerRef}>
                                                                    <div className="form-group mar-btm-5 ">
                                                                        {
                                                                            this.props.is_builder &&
                                                                            <div className="field-button-group"
                                                                                 style={{marginRight: (is_section ? "32px" : "5px")}}>
                                                                                <div style={{display: "inline"}}>
                                                                                    {
                                                                                        element !== "Section" && !is_section &&
                                                                                        <button style={{
                                                                                            backgroundColor: "#d6b27e",
                                                                                            borderRadius: "50%",
                                                                                            width: "22px",
                                                                                            height: "22px",
                                                                                            color: "white",
                                                                                            border: "none",
                                                                                            verticalAlign: "-2px",
                                                                                            marginRight: "8px",
                                                                                            fontWeight: "600",
                                                                                            fontSize: "14px",
                                                                                            padding: "0px"
                                                                                        }}>
                                                                                            <Popover
                                                                                                placement={"bottom"}
                                                                                                content={menu}
                                                                                                trigger="click"
                                                                                                open={this.state.activeIndex === index}
                                                                                                onClick={() => {
                                                                                                    this.setState({activeIndex: index});
                                                                                                }}
                                                                                                onOpenChange={(visible) => {
                                                                                                    if (!visible) {
                                                                                                        this.setState({activeIndex: -1});
                                                                                                    }
                                                                                                }}>
                                                                                                <p style={{
                                                                                                    width: "22px",
                                                                                                    marginBottom: "0px"
                                                                                                }}>+</p>
                                                                                            </Popover>
                                                                                        </button>
                                                                                    }
                                                                                    <p className="element-badge">{field.audit ? "Audit (Yes, No, N/A)" : field.text}</p>
                                                                                    {
                                                                                        field.custom_logic &&
                                                                                        <p className="logic-badge">Logic
                                                                                            Applied</p>
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    element !== "LineBreak" && element !== "PageBreak" &&
                                                                                    <button className="btn btn-discard"
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                this.setState({
                                                                                                    editFieldIndex: index,
                                                                                                    showEditFieldModal: true
                                                                                                });
                                                                                            }}>Edit</button>
                                                                                }
                                                                                {
                                                                                    element !== "Section" &&
                                                                                    <button className="btn btn-discard"
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                this.copyField(field, index)
                                                                                            }}>Copy</button>
                                                                                }
                                                                                {
                                                                                    element === "Section" &&
                                                                                    <button
                                                                                        className="btn btn-discard"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.copySection(field, index)
                                                                                        }}
                                                                                    >Copy</button>
                                                                                }
                                                                                <button className="btn btn-discard"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.checkBeforeDelete(field, index)
                                                                                        }}>Delete
                                                                                </button>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            element !== "Paragraph" && element !== "Header" && element !== "LineBreak" && element !== "PageBreak" && element !== "Section" && element !== "FileUploads" &&
                                                                            <label
                                                                                className={"form-label " + (field.required || field.audit ? "label-required" : "")}>
                                                                                <span>{safe_get(field, "label", "").replace(/&nbsp;/gi, '')}</span>
                                                                            </label>
                                                                        }
                                                                        {
                                                                            element === "Header" &&
                                                                            <h3 className="zero-blue"
                                                                                dangerouslySetInnerHTML={{__html: safe_get(field, "content", "")}}/>
                                                                        }
                                                                        {element === "FileUploads" && this.renderFileUploads(index, field)}
                                                                        {
                                                                            element === "Paragraph" &&
                                                                            <div
                                                                                className="form-group mar-btm-5 mar-top-10 zero-dark-grey">
                                                                                <span
                                                                                    dangerouslySetInnerHTML={{__html: safe_get(field, "content", "")}}/>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            element === "LineBreak" &&
                                                                            <hr/>
                                                                        }
                                                                        {
                                                                            element === "PageBreak" &&
                                                                            <div className="zero-blue text-center"
                                                                                 style={{
                                                                                     border: "1px dotted",
                                                                                     borderRadius: "3px",
                                                                                     height: "40px",
                                                                                     paddingTop: "10px"
                                                                                 }}>
                                                                                {/* {safe_get(field, "label", "PAGE BREAK")} */}
                                                                                PAGE BREAK
                                                                            </div>
                                                                        }
                                                                        {
                                                                            element === "RadioButtons" &&
                                                                            safe_get(field, "options", []).length > 0 &&
                                                                            field.options.map((option, index2) => (
                                                                                <Radio
                                                                                    id={field.id}
                                                                                    key={index2}
                                                                                    className={"radio-label font-weight-normal" + (field.canHaveDisplayHorizontal ? " option-inline" : "")}
                                                                                    style={{whiteSpace: 'normal'}}
                                                                                    value={option.key}
                                                                                    checked={option.key === safe_get(answer_data, "value", [undefined])[0]}
                                                                                    onChange={this.optionSelect}
                                                                                >{option.text}</Radio>
                                                                            ))
                                                                        }
                                                                        {
                                                                            element === "Checkboxes" &&
                                                                            safe_get(field, "options", []).length > 0 &&
                                                                            field.options.map((option, index2) => (
                                                                                <Checkbox
                                                                                    id={field.id}
                                                                                    key={index2}
                                                                                    className={"checkbox-label" + (field.canHaveDisplayHorizontal ? " option-inline" : "")}
                                                                                    style={{fontWeight: 'normal'}}
                                                                                    value={option.key}
                                                                                    onChange={this.checkboxSelect}
                                                                                    checked={safe_get(answer_data, "value", []).includes(option.key)}
                                                                                >{option.text}</Checkbox>
                                                                            ))
                                                                        }
                                                                        {
                                                                            element === "Dropdown" && this.renderDropdown(index, field, answer_data)
                                                                        }
                                                                        {
                                                                            element === 'DynamicListInput' && this.renderDynamicDropdown(index, field, answer_data)
                                                                        }
                                                                        {
                                                                            element === 'UserDropdown' && this.renderUserDropdown(index, field, answer_data)
                                                                        }
                                                                        {
                                                                            element === "Tags" && this.renderTags(index, field, answer_data)
                                                                        }
                                                                        {
                                                                            element === "TextInput" && this.renderTextInput(field, answer_data)
                                                                        }
                                                                        {
                                                                            element === "TextArea" && this.renderTextArea(field, answer_data)
                                                                        }
                                                                        {
                                                                            element === "NumberInput" && this.renderNumberInput(field, answer_data)
                                                                        }
                                                                        {
                                                                            element === "DatePicker" && this.renderDateInput(field, answer_data)
                                                                        }
                                                                        {
                                                                            element === 'TimePicker' && this.renderTimeInput(field, answer_data)
                                                                        }
                                                                        {
                                                                            element === "Signature" &&
                                                                            <SignatureField field_id={field.id}
                                                                                            saveSignature={this.saveSignature}
                                                                                            signature={safe_get(answer_data, "value", undefined)}
                                                                                            is_submission={is_submission}/>
                                                                        }
                                                                        {
                                                                            element === "Attachments" && this.renderAttachmentInput(field, answer_data)
                                                                        }
                                                                        {
                                                                            element === "Section" &&
                                                                            <Collapse className="form-collapse"
                                                                                      defaultActiveKey={field.id}>
                                                                                <Collapse.Panel header={field.label}
                                                                                                key={field.id}>
                                                                                    <FormFieldsRenderer
                                                                                        answers_data={this.state.answers_data}
                                                                                        form_uuid={form_uuid}
                                                                                        submission_uuid={this.props.submission_uuid}
                                                                                        form_fields={this.state.form_fields}
                                                                                        field_answers={[...answers]}
                                                                                        pages={[this.state.form_fields]}
                                                                                        page={0}
                                                                                        toolbar_items={section_toolbar_items}
                                                                                        icons={icons}
                                                                                        isSubmissionView={isSubmissionView}
                                                                                        is_submission={is_submission}
                                                                                        is_builder={is_builder}
                                                                                        is_section={true}
                                                                                        is_preview={this.props.is_preview}
                                                                                        isComment={this.props.isComment}
                                                                                        isAnalytics={isAnalytics}
                                                                                        section={field}
                                                                                        section_id={field.id}
                                                                                        saveFormFromSection={this.saveFormFromSection}
                                                                                        saveField={this.props.saveField}
                                                                                        deleteField={this.props.deleteField}
                                                                                        updateAnswers={this.updateAnswersFromSection}
                                                                                        setSubmissionLock={this.props.setSubmissionLock}
                                                                                        linkPostToField={this.props.linkPostToField}
                                                                                    />
                                                                                </Collapse.Panel>
                                                                            </Collapse>
                                                                        }
                                                                        {
                                                                            is_submission && safe_get(answer_data, "required_error", false) &&
                                                                            <div>
                                                                                <small className="error" style={{fontWeight: "500"}}>
                                                                                    A response is required.
                                                                                </small>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        !this.props.is_builder &&
                                                                        <FormFieldCommentAttachment
                                                                            key={index}
                                                                            index={index}
                                                                            form_uuid={form_uuid}
                                                                            submission_uuid={this.props.submission_uuid}
                                                                            field={field}
                                                                            answer_data={answer_data}
                                                                            is_submission={is_submission}
                                                                            is_builder={is_builder}
                                                                            is_preview={is_preview}
                                                                            isAnalytics={this.props.isAnalytics}
                                                                            updateField={this.updateField}
                                                                            saving={this.state.saving}
                                                                            linkPostToField={this.props.linkPostToField}
                                                                        />
                                                                    }
                                                                    {
                                                                        this.props.isSubmissionView && !this.props.hideScores && (field.is_scored || field.audit) &&
                                                                        <div className="thin-border mar-rgt-5" style={{
                                                                            display: "inline-block",
                                                                            padding: "8px"
                                                                        }}>
                                                                            {this.calculateFieldScore(field, safe_get(answer_data, "value", []))}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        !this.props.hideScores && this.isFailedField(field, safe_get(answer_data, "value", [])) &&
                                                                        <div className="thin-border" style={{
                                                                            display: "inline-block",
                                                                            padding: "8px"
                                                                        }}>
                                                                            Failed
                                                                        </div>
                                                                    }

                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                } else return null;
                                            })


                                        ))
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {
                        is_builder && is_section &&
                        <button style={{
                            backgroundColor: "#d6b27e",
                            borderRadius: "50%",
                            width: "22px",
                            height: "22px",
                            color: "white",
                            border: "none",
                            verticalAlign: "-2px",
                            marginRight: "8px",
                            fontWeight: "600",
                            fontSize: "14px",
                            padding: "0px"
                        }}>
                            <Popover placement={"bottom"} content={section_menu} trigger="click"
                                     open={this.state.activeSection === this.props.section_id} onClick={() => {
                                this.setState({activeSection: this.props.section_id});
                            }} onOpenChange={(visible) => {
                                if (!visible) {
                                    this.setState({activeSection: -1});
                                }
                            }}>
                                <p style={{width: "22px", marginBottom: "0px"}}>+</p>
                            </Popover>
                        </button>
                    }
                </div>
            );
        }

    }


}


const mapStateToProps = store => {
    return {
        user: safe_get(store, "user.user", []),
        org_uuid: safe_get(store, "org_helper.organization.organization_uuid", ""),
        current_team: safe_get(store, "teams_helper.team", ""),
        current_team_uuid: safe_get(store, "teams_helper.team.uuid", ""),
        teams: safe_get(store, "teams_helper.teams", "")
    }
}
export default withRouter(connect(mapStateToProps)(FormFieldsRenderer));


/**
 * Scales a canvas to a set width, maintaining aspect ratio.
 * @param {HTMLCanvasElement} canvas 
 * @param {number} newWidth 
 * @returns {HTMLCanvasElement} A scaled copy of the canvas
 */
function scaleCanvas(canvas, newWidth) {
    const originalWidth = canvas.width;
    const originalHeight = canvas.height;

    // Calculate the aspect ratio
    const aspectRatio = originalHeight / originalWidth;

    // Calculate the new height maintaining the aspect ratio
    const newHeight = Math.round(newWidth * aspectRatio);

    // Create a temporary canvas to store the original content
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = newWidth;
    tempCanvas.height = newHeight;
    const tempContext = tempCanvas.getContext('2d');
    tempContext.drawImage(canvas, 0, 0, newWidth, newHeight);
    return tempCanvas;
}

class SignatureField extends Component {

    sigPad = {};

    constructor(props) {
        super(props);

        this.state = {
            signature: props.signature,
            sigSaved: props.signature !== undefined
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.signature !== prevProps.signature && !this.props.is_submission) {
            this.setState({signature: this.props.signature});
        }
    }

    componentDidMount() {
        if (!this.state.signature && !this.props.is_submission) {
            this.sigPad.off();
        }
    }

    clearSignature = () => {
        if (this.state.signature) {
            this.setState({signature: undefined, sigSaved: false});
        } else {
            this.sigPad.clear();
        }

        this.props.saveSignature(this.props.field_id, "")

        this.setState({
            sigSaved: false
        }, () => {
            this.sigPad.on();
        });
    }

    saveSignature = () => {
        if (this.state.signature) {
            return;
        }
        this.setState({sigSaved: true});
        const canvas = this.sigPad.getTrimmedCanvas();
        const scaledCanvas = scaleCanvas(canvas, 500);
        const data = scaledCanvas.toDataURL("image/png");
        this.props.saveSignature(this.props.field_id, data);
    }

    render() {
        return (
            <div className="">
                <div className="print-avoid-breaks" style={{ position: "relative" }}>
                    {this.state.signature ? (
                        <img className="signature" src={this.state.signature} />
                    ) : (
                        <SignatureCanvas
                            penColor="black"
                            backgroundColor="#f6f6f6"
                            ref={(ref) => {
                                this.sigPad = ref;
                            }}
                            onEnd={() => {
                                this.saveSignature(this.props.field_id);
                            }}
                        />
                    )}
                </div>
                {this.props.is_submission && (
                    <div className="signature-pad--footer">
                        <div className="signature-pad--actions">
                            <button type="button" className="ButtonLink zero-light-blue" onClick={this.clearSignature}>
                                Clear
                            </button>
                            <div className="description">
                                {this.props.is_submission && !this.state.sigSaved && "Sign above"}
                                {this.props.is_submission && this.state.sigSaved && "Saved"}
                            </div>

                            {/* <div>
                                <button type="button" className="button save" onClick={() => { this.saveSignature(this.props.field_id) }}>Save</button>                                
                            </div> */}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}